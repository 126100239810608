
import {
  Component, Prop, PropSync,
} from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Resource } from '@api-platform/api-doc-parser/lib/Resource';
import FormatLabelMixin from '@/mixins/FormatLabelMixin';
import FilterList from '@/components/List/FilterList.vue';
import FieldToStringGuesser from '@/components/List/FieldToStringGuesser.vue';
import { FilterMapping } from '@/types/shared/Filtering';

@Component({
  components: { FieldToStringGuesser, FilterList },
})
export default class FilterCard extends mixins(FormatLabelMixin) {
  /** Whether to show the reset button and the no filters selected text */
  @Prop({ type: Boolean, default: false }) showReset!: boolean;

  @Prop({ type: Object, required: true }) resource!: Resource;

  /**
   * Filter is declared two times. This is because of Typescript's type-hinting.
   * First it is declared as a normal prop, so owe can access it with correct type-hinting.
   * Secondly it is used as a synced Prop so we are also able to set the values of filters.
   */
  @Prop({ type: Object }) filters!: FilterMapping[];

  @PropSync('filters', { type: Array, required: true }) syncedFilters!: FilterMapping[];

  private showFilters = false;

  get nonEmptyFilters() {
    return this.filters.filter((f) => f.value);
  }

  filter() {
    this.$emit('filter');
  }

  resetFilters() {
    this.showFilters = false;
    this.$emit('reset-all');
  }

  resetFilter(filter: string) {
    this.$emit('reset-filter', filter);
  }
}
