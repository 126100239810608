
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { TemplateSegment } from '@/types/resources/Template';
import { TemplateSegmentConfiguration } from '@/types/resources/TemplateConfiguration';

@Component
export default class ContentBlockConfiguration extends Vue {
  @Prop({ type: Object, required: true }) segment!: TemplateSegment

  @Prop({ type: Array, default: null }) value!: Partial<TemplateSegmentConfiguration>[] | null;

  @Prop({ type: String, default: '' }) locale!: string;

  private localConfiguration: Partial<TemplateSegmentConfiguration>[] = [];

  get configurations() {
    return this.segment.configurations;
  }

  generateId(): string {
    return `collection_configuration_${this.locale}_${Math.random().toString(20).slice(2, 10)}`;
  }

  created() {
    if (Array.isArray(this.value)) {
      this.localConfiguration = [...this.value];
    }
  }

  @Watch('localConfiguration', { deep: true })
  updateConfiguration(value: Partial<TemplateSegmentConfiguration>[]) {
    this.$emit('input', value);
  }
}
