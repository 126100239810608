
import { Vue, Component, Watch } from 'vue-property-decorator';
import NonExistentCreateForm from '@/utils/error/NonExistentCreateForm';
import { Resource } from '@api-platform/api-doc-parser/lib';
import CreateForm from '@/components/resources/CreateForm.vue';

@Component({
  components: { CreateForm },
})
export default class CreateResource extends Vue {
  private createForm: string | NonExistentCreateForm | null = null;

  private resource: Resource | null = null;

  backToList() {
    if (this.$route.query.hierarchyRef && typeof this.$route.query.hierarchyRef === 'string') {
      this.$router.push({
        name: 'contents-hierarchy',
        params: {
          hierarchyNodeId: this.$route.query.hierarchyRef,
        },
      });
    } else {
      this.$router.push({
        name: 'list-resource',
        params: { resourceTitle: this.$route.params.resourceTitle },
      });
    }
  }

  goBack() {
    if (window) {
      window.history.back();
    }
  }

  async mounted() {
    await this.loadCreateForm();
  }

  @Watch('$route.params')
  @Watch('$root.$i18n.locale')
  async loadCreateForm() {
    await this.$store.dispatch('api/loadApiDocumentation');
    this.resource = this.$store.state.api.resources.find(
      (r: Resource) => r.url.split('/').reverse()[0] === this.$route.params.resourceTitle,
    );

    if (this.resource) {
      this.createForm = `${this.resource.title}CreateForm`;

      if (this.$options?.components && !this.$options.components[this.createForm]) {
        // eslint-disable-next-line no-console
        console.error(`The Edit Component for this resource is missing or is not called "${this.createForm}"`);
        this.createForm = new NonExistentCreateForm();
      }
    }
  }
}
