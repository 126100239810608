

import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { CreateFormInterface } from '@/types/interfaces/CreateResource/CreateFormInterface';
import { School } from '@/types/resources/School';

@Component({
  components: { ValidationProvider },
})
export default class SchoolCreateForm extends Vue implements CreateFormInterface {
  private school: Partial<School> = {
    name: '',
    postalCode: '',
  };

  prepareLocalResource(): Partial<School> {
    return {
      name: this.school.name,
      postalCode: this.school.postalCode,
    };
  }

  resetLocalResource(): void {
    this.school = {
      name: '',
      postalCode: '',
    };
  }
}
