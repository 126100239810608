
import {
  Component, Prop, PropSync,
} from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Resource } from '@api-platform/api-doc-parser/lib/Resource';
import FormatLabelMixin from '@/mixins/FormatLabelMixin';
import { FilterMapping } from '@/types/shared/Filtering';

@Component
export default class FilterList extends mixins(FormatLabelMixin) {
  @Prop({ type: Object, required: true }) resource!: Resource;

  /** All available filters. Filter contains a value field for user's input */
  @PropSync('filters', { type: Array, required: true }) syncedFilters!: FilterMapping[];

  getTypeOfField(filter: FilterMapping): string {
    if (this.resource?.fields) {
      const field = this.resource.fields.find((f) => f.name === filter.property.replace('translations.', ''));

      if (field?.range) {
        const match = field.range.match('.*#(.*)$');

        if (match && match.length > 1) {
          return match[1];
        }
      }
    }

    return '';
  }

  /**
   * Go through all filters and remove the irrelevant ones.
   * Also rename filters starting with "translation"
   */
  get filterableProperties() {
    return this.syncedFilters.filter((f) => !(
      f.variable.includes('properties')
        || f.variable.includes('order')
        || f.variable.includes('groups')
        || f.variable.includes('[]')
    ));
  }

  onFilter($event: { [key: string]: string }) {
    this.$emit('filter');
    if ($event.key === 'Enter') {
      this.$emit('close');
    }
  }
}
