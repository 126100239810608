
import {
  Vue, Component, PropSync, Prop,
} from 'vue-property-decorator';
import ContentBlockFormWrapper
  from '@/components/resources/Content/ContentBlock/ContentBlockFormWrapper.vue';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';
import { ContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { TemplateSegment } from '@/types/resources/Template';

@Component({
  components: { ContentBlockFormWrapper },
})
export default class EmptyContentBlockForm extends Vue implements ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @Prop({ type: Boolean, default: false }) showDelete!: boolean;

  @Prop({ type: Boolean, default: false }) draggable!: boolean;

  @Prop({ type: Object, default: undefined }) segment!: Partial<TemplateSegment> | undefined;

  @PropSync('contentBlock', {
    type: Object,
    required: true,
  }) syncedContentBlock!: ContentBlockInterface;
}
