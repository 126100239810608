
import { Vue, Component, Prop } from 'vue-property-decorator';
import LanguageSwitcher from '@/components/Layout/LanguageSwitcher.vue';
import { ToolbarAction } from '@/types/shared/ToolbarAction';

@Component({
  components: { LanguageSwitcher },
})
export default class Toolbar extends Vue {
  @Prop({ type: Array, default: undefined }) actions!: Array<ToolbarAction>;

  private loadingExport = false;

  async downloadRankingExport() {
    this.loadingExport = true;

    try {
      const protocol = process.env.VUE_APP_API_HTTPS === 'true' ? 'https' : 'http';
      const path = `${protocol}://${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api`;
      const file = await this.$api.get(`${path}/scores/export`);

      const aLink = document.createElement('a');
      aLink.download = 'export.csv';
      aLink.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(file.data)}`;
      const evt = new MouseEvent('click');
      aLink.dispatchEvent(evt);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err, err.message, err.response);
    }
    this.loadingExport = false;
  }
}
