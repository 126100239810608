
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Treeselect, ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { ValidationProvider } from 'vee-validate';
import { User } from '@/types/resources/User';
import { EditFormInterface } from '@/types/interfaces/EditResource/EditFormInterface';
import { Team } from '@/types/resources/Team';
import { ApiResource } from '@/types/resources/ApiResource';

@Component({
  components: { ValidationProvider, Treeselect },
})
export default class TeamEditForm extends Vue implements EditFormInterface {
  @Prop({ type: Object, required: true }) resource!: Team;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  $refs!: {
    captainTreeselect: any;
    membersTreeselects: any;
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  private team: Partial<Team> = {
    name: '',
    captain: '',
    members: [],
  };

  loadLocalResource() {
    this.team.name = this.resource.name;
    if (typeof this.resource.captain === 'string') {
      this.team.captain = this.resource.captain;
    } else {
      this.team.captain = this.resource.captain['@id'];
    }
    this.team.members = [];
    this.resource.members.forEach((m: ApiResource|string) => {
      if (!Array.isArray(this.team.members)) {
        this.team.members = [];
      }
      if (Array.isArray(this.team.members)) {
        if (typeof m === 'object') {
          this.team.members.push(m['@id']);
        } else {
          this.team.members.push(m);
        }
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async loadUsers({ action, searchQuery, callback }: any) {
    if (action === ASYNC_SEARCH) {
      const response = await this.$api.get('/api/users', {
        params: {
          locale: this.$root.$i18n.locale,
          email: searchQuery,
          nickname: searchQuery,
          properties: ['email', 'nickname'],
        },
      });

      if (response.status === 200 && response.data['hydra:member']) {
        callback(null, response.data['hydra:member'].map((user: User) => ({
          id: user['@id'],
          label: user.nickname ?? '',
        })));
      }
    }
  }

  prepareLocalResource() {
    return this.team;
  }

  resetLocalResource() {
    this.loadLocalResource();
  }

  async mounted() {
    if (this.resource?.captain) {
      let response = null;
      if (typeof this.resource.captain === 'object') {
        response = await this.$api.get(this.resource.captain['@id']);
      } else {
        response = await this.$api.get(this.resource.captain);
      }
      if (response && response.status === 200) {
        let node = this.$refs.captainTreeselect.forest.nodeMap[response.data['@id']];
        if (node) {
          node.label = response.data.nickname;
        } else {
          node = { label: response.data.nickname };
        }
      }
    }

    if (this.resource?.members?.length) {
      this.$nextTick(async () => {
        const promises: Promise<Team>[] = [];
        this.resource.members.forEach((m: ApiResource|string) => {
          if (typeof m === 'object') {
            promises.push(this.$api.get(m['@id']));
          } else {
            promises.push(this.$api.get(m));
          }
        });
        const memberResponses = await Promise.all(promises);
        memberResponses.forEach((response, idx) => {
          let node = this.$refs.membersTreeselects[idx].forest.nodeMap[response.data['@id']];
          if (node) {
            node.label = response.data.nickname;
          } else {
            node = { label: response.data.nickname };
          }
        });
      });
    }
  }

  @Watch('resource')
  resourceChanged() {
    this.loadLocalResource();
  }
}
