
import Component from 'vue-class-component';
import Vue from 'vue';
import LoginForm from '@/components/userInformation/LoginForm.vue';

@Component({
  components: { LoginForm },
})
export default class ListPage extends Vue {
  message = 'Login Page'
}
