
import { Vue, Component } from 'vue-property-decorator';
import SidebarUserPopover from '@/components/userInformation/SidebarUserPopover.vue';
import { Resource } from '@api-platform/api-doc-parser/lib/Resource';
import HierarchyView from '@/views/contents/HierarchyView.vue';

@Component({
  components: { SidebarUserPopover, HierarchyView },
})
export default class Sidebar extends Vue {
  private username: string = this.$store.state.auth.user.email;

  private settingsRelatedResources = [
    'HierarchyNode',
  ];

  private ignoreResources = [
    'ContentBlock',
    'CollectionContentBlock',
    'ReferenceContentBlock',
  ]

  get resources(): Resource[] {
    return this.$store.getters['api/getResources'];
  }

  get allowedResources() {
    return this.resources.filter((resource) => {
      if (this.settingsRelatedResources.some((r) => r === resource.title)) {
        return false;
      }

      if (this.ignoreResources.some((r) => r === resource.title)) {
        return false;
      }

      if (resource.title === 'Template' || resource.title === 'Content') {
        if (this.$store.state.auth?.user?.roles?.length) {
          return this.$store.state.auth.user.roles.includes('ROLE_SUPER_ADMIN');
        }

        return false;
      }

      return true;
    });
  }

  get isAdmin() {
    return this.$store.state.auth.user?.roles?.includes('ROLE_SUPER_ADMIN');
  }

  /**
   * Get the url of the resource without the API host, port and prefix.
   * At the moment, we assume, that the resources url is a top-level path
   * of the API, eg. jkcms.jkweb.ch/api/users.
   * @param resource
   */
  resourceUrl(resource: Resource) {
    const { url } = resource;
    return url.split('/').reverse()[0];
  }

  getIcon(resourceName: string) {
    const iconMap: {[key: string]: string} = {
      User: 'users',
      ImageObject: 'images',
      MediaObject: 'file-upload',
      Content: 'file-alt',
      Template: 'file-import',
    };

    if (iconMap[resourceName]) {
      return iconMap[resourceName];
    }

    return 'folder-tree';
  }

  navigate(path: string) {
    this.$emit('navigate', path);
  }

  async mounted() {
    await this.$store.dispatch('hierarchy/loadHierarchyNodes');
  }
}
