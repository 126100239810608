
import { Prop, Component, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { EditFormInterface } from '@/types/interfaces/EditResource/EditFormInterface';
import { School } from '@/types/resources/School';

@Component({
  components: { ValidationProvider },
})
export default class SchoolEditForm extends Vue implements EditFormInterface {
  @Prop({ type: Object, required: true }) resource!: School;

  private school: Partial<School>|null = null;

  loadLocalResource(): void {
    this.school = { ...this.resource };
  }

  prepareLocalResource(): Partial<School>|null {
    return this.school;
  }

  resetLocalResource() {
    this.loadLocalResource();
  }
}
