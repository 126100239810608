import { render, staticRenderFns } from "./FilterCard.vue?vue&type=template&id=3b5b84f2&scoped=true"
import script from "./FilterCard.vue?vue&type=script&lang=ts"
export * from "./FilterCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5b84f2",
  null
  
)

export default component.exports