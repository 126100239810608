
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { HierarchyNode, HierarchyNodeType } from '@/types/resources/HierarchyNode';
import { Content } from '@/types/resources/Content';
import { ReferenceContentBlockInterface } from '@/types/resources/ContentBlockInterface';

@Component
export default class HierarchyNodeView extends Vue {
  @Prop({ type: Object, required: true }) node!: HierarchyNode;

  @Prop({ type: Object, default: null }) parent!: Content | null;

  @Prop({ type: String, default: '' }) filterQuery!: string;

  private loading = true;

  private contents: Content[] = [];

  private deleteTimeout: number | undefined;

  private showChildren: {[key: string]: boolean} = {};

  private toReset: Content|null = null;

  private resetRankingLoading = '';

  private loadingExport = '';

  deletedContents: { oldIndex: number; content: Content }[] = [];

  // The following three properties are used for frontend paginating
  private currentPageContents: Content[] = [];

  private itemsPerPage = 10;

  private currentPage = 1;

  get paginate() {
    return this.parent === null;
  }

  get frontendUrl() {
    return process.env.VUE_APP_FRONTEND;
  }

  showResetRankingModal(event: Content) {
    this.toReset = event;
    this.$bvModal.show(`resetRankingModal${event.id}`);
  }

  async downloadEventExport(content: Content) {
    this.loadingExport = content['@id'];

    try {
      const protocol = process.env.VUE_APP_API_HTTPS === 'true' ? 'https' : 'http';
      const path = `${protocol}://${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api`;
      const file = await this.$api.get(`${path}/contents/${content.id}/export`);

      const aLink = document.createElement('a');
      aLink.download = 'export.csv';
      aLink.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(file.data)}`;
      const evt = new MouseEvent('click');
      aLink.dispatchEvent(evt);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err, err.message, err.response);
    }
    this.loadingExport = '';
  }

  async clearEventRanking(event: Content): Promise<void> {
    try {
      this.resetRankingLoading = event['@id'];
      await this.$api.put(`${event['@id']}/reset-ranking`, {});
      this.$bvToast.toast(
        this.$t('global.rankingReset', [event.name]) as string,
        {
          toaster: 'b-toaster-top-center',
          variant: 'info',
          noCloseButton: false,
          noHoverPause: false,
          title: undefined,
          autoHideDelay: 4000,
        },
      );
    } catch (err) {
      // eslint-disable-next-line
      console.error(err, err.message, err.response);
      this.$bvToast.toast(
        this.$t('global.errorWhileDeleting') as string,
        {
          toaster: 'b-toaster-top-center',
          variant: 'danger',
          noCloseButton: true,
          noHoverPause: true,
          autoHideDelay: 4000,
        },
      );
    }
    this.resetRankingLoading = '';
  }

  toggleVisibilityOfChildren(contentIri: string) {
    this.showChildren[contentIri] = !this.showChildren[contentIri];
  }

  getViewLink(content: Content) {
    const elements = content['@id'].split('/').reverse();

    return `/${elements[1]}/${elements[0]}`;
  }

  navigateToResource(iri: string) {
    const elements = iri.split('/').reverse();

    this.$router.push({
      name: 'view-resource',
      params: {
        resourceTitle: elements[1],
        resourceId: elements[0],
      },
    });
  }

  addContent() {
    let template: string = typeof this.node.template === 'object' && this.node.template?.id ? this.node.template?.id : '';
    if (typeof this.node.template === 'string') {
      template = this.node.template;
    }

    this.$router.push({
      name: 'create-resource-with-template',
      params: {
        resourceTitle: 'contents',
        templateId: template,
      },
      query: {
        hierarchyRef: this.$route.params.hierarchyNodeId,
      },
    });
  }

  async deleteContent(content: Content) {
    try {
      await this.$api.delete(content['@id']);
      this.deletedContents.pop();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e, e.response, e.response?.data);
      this.$bvToast.toast(
        this.$t('global.errorWhileDeleting') as string,
        {
          toaster: 'b-toaster-top-center',
          variant: 'danger',
          noCloseButton: true,
          noHoverPause: true,
          autoHideDelay: 4000,
        },
      );
    }
  }

  cancelDeletion() {
    window.clearTimeout(this.deleteTimeout);

    const undoneContent = this.deletedContents.pop();
    if (undoneContent) {
      this.contents.splice(undoneContent.oldIndex, 0, undoneContent.content);

      this.$bvToast.toast(
        this.$t('global.deletionSomethingUndone', [undoneContent.content.name]) as string,
        {
          toaster: 'b-toaster-top-center',
          variant: 'info',
          autoHideDelay: 4000,
          noCloseButton: true,
          noHoverPause: true,
        },
      );
    }
  }

  deleteContentToast(content: Content) {
    const contentIdx = this.contents.findIndex((c) => c['@id'] === content['@id']);

    if (contentIdx > -1) {
      this.deleteTimeout = window.setTimeout(() => {
        this.deleteContent(content);
      }, 5000);

      this.deletedContents.push({
        oldIndex: contentIdx,
        content: this.contents.splice(contentIdx, 1)[0],
      });

      const vNodesMsg = this.$createElement(
        'p',
        { class: ['text-center', 'mb-0'] },
        [
          this.$t('global.deletedSomething', [content.name]) as string,
          '. ',
          this.$createElement(
            'a',
            {
              class: ['undo-link'],
              on: { click: this.cancelDeletion },
            },
            [this.$t('global.undo') as string, '...'],
          ),
        ],
      );

      this.$bvToast.toast(
        [vNodesMsg], {
          toaster: 'b-toaster-top-center',
          variant: 'warning',
          appendToast: true,
          noCloseButton: true,
          autoHideDelay: 4000,
        },
      );
    }
  }

  async loadContents() {
    this.loading = true;
    const params: { [key: string]: string | object } = {
      properties: ['name', 'id'],
    };

    if (typeof this.node.template === 'string') {
      params.template = this.node.template;
    } else if (this.node.template?.id) {
      params.template = this.node.template.id;
    }

    if (this.filterQuery) {
      params['translations.name'] = this.filterQuery;
    }

    if (this.node.segment && typeof this.node.segment === 'object' && this.parent) {
      if (this.node.type === HierarchyNodeType.segmentOnParent) {
        if (this.node.segment?.contentBlockType === 'collection') {
          // Find correct collectionBlock on Parent
          const collectionBlock = this.parent.contentBlocks.find(
            (b) => {
              // TODO: Handle cases when objects are strings
              if (b.templateSegment && typeof b.templateSegment === 'object'
                && this.node?.segment && typeof this.node.segment === 'object') {
                return b.templateSegment.id === this.node.segment.id;
              }

              return false;
            },
          );
          if (collectionBlock && collectionBlock['@id']) {
            // GET it
            const response = await this.$api.get(collectionBlock['@id']);
            if (response.status === 200) {
              // Loop over its referenceBlocks and add the references
              response.data.contentBlocks.forEach((refBlock: ReferenceContentBlockInterface) => {
                if (typeof refBlock.reference === 'object') {
                  this.contents.push(refBlock.reference);
                }
              });
            }
          } // Finish case "segmentOnParent with Collection"
        } else if (this.node.segment.contentBlockType === 'reference') {
          const referenceBlock = this.parent.contentBlocks.find(
            (b) => {
              // TODO: Handle cases when objects are strings
              if (b.templateSegment && typeof b.templateSegment === 'object'
                && this.node?.segment && typeof this.node.segment === 'object') {
                return b.templateSegment.id === this.node.segment.id;
              }

              return false;
            },
          );
          if (referenceBlock) {
            this.contents.push(referenceBlock.reference);
          }
        } // Finish case "segmentOnParent with Reference"
      } else if (this.node.type === HierarchyNodeType.segmentOnChild) {
        if (this.node.segment.contentBlockType === 'collection') {
          // TODO: How do we handle this? Is this allowed?
        } else if (this.node.segment.contentBlockType === 'reference') {
          const response = await this.$api.get('/api/reference_content_blocks', {
            params: {
              'content.template': typeof this.node.template === 'string' ? this.node.template : this.node.template?.id,
              templateSegment: this.node.segment.id,
              reference: this.parent.id,
              properties: ['id'], // We only need id
            },
          });
          if (response.status === 200 && response.data['hydra:member']) {
            const referenceBlockIds = response.data['hydra:member']
              .map((referenceBlock: ReferenceContentBlockInterface) => referenceBlock.id);
            if (referenceBlockIds.length) {
              const contentsResponse = await this.$api.get('/api/contents', {
                params: {
                  contentBlocks: referenceBlockIds,
                },
              });
              if (contentsResponse.status === 200 && response.data['hydra:member']) {
                contentsResponse.data['hydra:member'].forEach((content: Content) => {
                  this.contents.push(content);
                });
              }
            }
          }
        }
      }
    } else if (this.node.type === HierarchyNodeType.none) {
      const response = await this.$api.get('/api/contents', {
        params,
      });
      if (response.status === 200 && response.data['hydra:member']) {
        this.contents = response.data['hydra:member'];
        this.contents.forEach((c) => {
          this.$set(this.showChildren, c['@id'], true);
        });
      }
    }

    // As long as there is no backend sorting, sort Questions here
    this.contents.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    // Paginate only the top-most level
    if (this.paginate) {
      this.currentPageContents = this.contents.slice(0, this.itemsPerPage);
    } else {
      this.currentPageContents = this.contents;
    }

    this.loading = false;
  }

  async mounted() {
    await this.loadContents();
  }

  /**
   * Used for frontend paginating
   */
  @Watch('currentPage')
  updateDisplayedContents() {
    this.currentPageContents = this.contents.slice(
      (this.itemsPerPage * (this.currentPage - 1)),
      this.itemsPerPage * this.currentPage,
    );
  }

  @Watch('node')
  async nodeChanged() {
    this.contents = [];
    await this.loadContents();
  }

  @Watch('filterQuery')
  filterContents() {
    this.loadContents();
  }
}
