
import { Component } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import Draggable from 'vuedraggable';
import { CreateFormInterface } from '@/types/interfaces/CreateResource/CreateFormInterface';
import { Template } from '@/types/resources/Template';
import TemplateSegmentForm from '@/components/resources/Template/TemplateSegmentForm.vue';
import { mixins } from 'vue-class-component';
import TemplateMixin from '@/mixins/Template/TemplateMixin';
import { TemplateStatus } from '@/types/Enums';

@Component({
  components: { ValidationProvider, TemplateSegmentForm, Draggable },
})
export default class TemplateCreateForm
  extends mixins(TemplateMixin) implements CreateFormInterface {
  protected template: Partial<Template> = {};

  private templateConfigurationValid: boolean | null = null;

  resetLocalResource(): void {
    this.template = {
      name: '',
      status: TemplateStatus.published,
      configurations: [],
      segments: [],
    };

    this.addTemplateSegment();
  }

  get templateConfiguration(): string {
    return JSON.stringify(this.template.configurations, undefined, 4);
  }

  set templateConfiguration(value: string) {
    try {
      this.template.configurations = JSON.parse(value);
      this.templateConfigurationValid = true;
    } catch (e) {
      this.templateConfigurationValid = false;
    }
  }
}
