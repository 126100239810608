
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { mixins } from 'vue-class-component';
import ResourceInfoMixin from '@/mixins/ResourceInfoMixin';
import { Resource } from '@api-platform/api-doc-parser/lib';
import { CreateFormInterface } from '@/types/interfaces/CreateResource/CreateFormInterface';
import { Violation, ViolationMapping } from '@/types/shared/Violations';

@Component({
  components: { ValidationObserver },
})
export default class CreateForm extends mixins(ResourceInfoMixin) {
  @Prop({ type: Object, required: true }) resource!: Resource;

  @Prop({ type: String, required: true }) createFormType!: string;

  private saving = false;

  private success: null | boolean = null;

  private backendErrors: string[] = [];

  public $refs!: {
    createForm: InstanceType<typeof ValidationObserver>;
    createComponent: Vue & CreateFormInterface;
  }

  showSuccess() {
    this.success = true;
    setTimeout(() => {
      this.success = null;
      this.$refs.createForm.reset();
      this.$refs.createComponent.resetLocalResource();
      this.$emit('resource-created');
    }, 1000);
  }

  async submitResource() {
    this.saving = true;
    // Access prepareLocalResource from Children
    const resourceToPost = await this.$refs.createComponent.prepareLocalResource();

    if (resourceToPost === null) {
      this.$refs.createForm.setErrors({ name: this.$t('critical_error') as string });
      return;
    }

    try {
      let response;

      if (resourceToPost instanceof FormData) {
        response = await this.$api.post(this.resource.url, resourceToPost, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        response = await this.$api.post(this.resource.url, resourceToPost);
      }

      if (response.status === 201) {
        this.saving = false;
        this.$refs.createForm.reset();
        await this.showSuccess();
      }
    } catch (e) {
      if (e.response?.data['@type'] === 'ConstraintViolationList') {
        const violations: ViolationMapping = {};
        e.response.data.violations.forEach((v: Violation) => {
          violations[v.propertyPath] = [v.message];
        });
        this.$refs.createForm.setErrors(violations);
        this.backendErrors = e.response.data['hydra:description'].split('\n');
      }
      this.saving = false;
    }
  }

  mounted() {
    this.$refs.createComponent.resetLocalResource();
  }
}
