
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import { HierarchyNode, HierarchyNodeType } from '@/types/resources/HierarchyNode';
import { ASYNC_SEARCH, Treeselect } from '@riophae/vue-treeselect';
import { hierarchyNodeTypes } from '@/utils/hierarchyNodes';
import { Template, TemplateSegment } from '@/types/resources/Template';
import { ContentBlockCollectionConfiguration } from '@/types/interfaces/Template/ContentBlockConfiguration';

@Component({
  components: { Treeselect },
})
export default class HierarchyNodeForm extends Vue {
  @Prop({ type: [Array], required: true }) index!: number[] | null;

  @PropSync('name', { type: String, default: '' }) syncedName!: string;

  @PropSync('type', { type: String, default: 'none' }) syncedType!: string;

  @PropSync('template', { type: String, default: null }) syncedTemplate!: string | null;

  @PropSync('segment', { type: String, default: null }) syncedSegment!: string | null;

  @PropSync('children', { type: Array, default: () => [] }) syncedChildren!: Partial<HierarchyNode>[];

  private templates: Template[] = []

  get types() {
    if (this.index && this.index.length < 2) {
      return [{ id: HierarchyNodeType.none, label: this.$t('settings.hierarchy.node.type.none') }];
    }
    return hierarchyNodeTypes.map((t) => ({
      id: t,
      label: this.$t(`settings.hierarchy.node.type.${t}`),
    })).filter((t) => t.id !== HierarchyNodeType.none);
  }

  get segments() {
    if (this.syncedType === HierarchyNodeType.segmentOnChild) {
      const template = this.templates.find((t) => t['@id'] === this.syncedTemplate);
      if (template?.segments) {
        return template.segments.map((s) => ({
          id: s['@id'],
          label: s.name,
        }));
      }
    } else if (this.syncedType === HierarchyNodeType.segmentOnParent) {
      /* eslint-disable @typescript-eslint/ban-ts-ignore */
      // @ts-ignore
      const templateId = this.$parent.$parent.template;
      // @ts-ignore
      const template = this.$parent.$parent.templates.find((t: Template) => t['@id'] === templateId);

      if (template?.segments) {
        return template.segments.filter((s: TemplateSegment) => {
          if (s.contentBlockType === 'reference') {
            return true;
          }

          if (
            s.contentBlockType === 'collection' && s.config
            && (s.config as ContentBlockCollectionConfiguration)?.allowedTypes
          ) {
            return (s.config as ContentBlockCollectionConfiguration).allowedTypes.length === 1
             && (s.config as ContentBlockCollectionConfiguration).allowedTypes[0] === 'reference';
          }

          return false;
        }).map((s: TemplateSegment) => ({
          id: s['@id'],
          label: s.name,
        }));
      }
      /* eslint-enable */
    }

    return [];
  }

  addNode(index: number[]) {
    this.$emit('add-node', index);
  }

  removeNode(index: number[]) {
    this.$emit('remove-node', index);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async loadTemplates({ action, searchQuery, callback }: any) {
    if (action === ASYNC_SEARCH) {
      const params = {
        locale: this.$root.$i18n.locale,
        name: searchQuery,
      };

      try {
        const response = await this.$api.get('/api/templates', {
          params,
        });

        if (response.status === 200 && response.data['hydra:member'] && Array.isArray(response.data['hydra:member'])) {
          this.templates = response.data['hydra:member'];
          callback(null, this.templates.map((template) => ({
            id: template['@id'],
            label: template.name,
          })));
        }
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }
    }
  }

  /**
   * This function loads the cached nodemaps of the Treeselects.
   * This is necessary to know the labels when the user has not yet searched,
   * as they would normally get loaded asynchronously.
   * The many ts-ignores are there, because I have not yet figured out how
   * to tell Typescript that this['syncedTemplate'] is a valid way to access
   * properties of this component.
   */
  async preloadTreeselectNodemapEntry(type: string) {
    /* eslint-disable @typescript-eslint/ban-ts-ignore, @typescript-eslint/no-explicit-any */
    // @ts-ignore
    if (this[`synced${type}`]) {
      // @ts-ignore
      const response = await this.$api.get(this[`synced${type}`], {
        params: {
          properties: ['name', 'segments'],
        },
      });

      if (response.status === 200) {
        // @ts-ignore
        (this.$refs[`treeselect${type}`] as any).forest.nodeMap[this[`synced${type}`]].label = response.data.name;
        this.templates.push(response.data);
      }
    }
  }

  async mounted() {
    await this.preloadTreeselectNodemapEntry('Template');
    await this.preloadTreeselectNodemapEntry('Segment');
  }
}
