
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class LoginForm extends Vue {
  public $refs!: {
    email: HTMLInputElement;
  };

  private error = '';

  private email = '';

  private password = '';

  private loading = false;

  async submit() {
    if (this.loading) {
      return;
    }

    try {
      this.error = '';
      this.loading = true;
      await this.$store.dispatch('auth/login', {
        email: this.email,
        password: this.password,
      });
      // TODO: Check roles and do not redirect if not ADMIN
      await this.$store.dispatch('hierarchy/loadHierarchyNodes');

      const node = this.$store.state.hierarchy.hierarchyNodes[0];
      if (node) {
        await this.$router.push(`/contents/hierarchy/${node['@id'].split('/').reverse()[0]}`);
      } else {
        await this.$router.push('/');
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.$refs.email.focus();

      if (e?.response?.data?.message) {
        this.error = e.response.data.message;
      } else {
        this.error = e.message;
      }

      // eslint-disable-next-line
      console.error(e.message);
    }
  }
}
