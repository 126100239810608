
import {
  Vue, Component, Prop, Emit,
} from 'vue-property-decorator';
import { ContextMenuInterface } from '@/types/interfaces/ContextMenu/ContextMenuInterface';
import { ContextMenuOption } from '@/types/interfaces/ContextMenu/ContextMenuOptionInterface';

@Component
export default class ContextMenu extends Vue implements ContextMenuInterface {
  @Prop({ type: Array, default: () => [] }) options!: Array<ContextMenuOption>;

  $refs!: {
    contextMenu: HTMLElement;
  }

  // eslint-disable-next-line
  public data: any;

  // eslint-disable-next-line
  openMenu(data: any, event: MouseEvent) {
    event.preventDefault();
    this.data = data;
    this.$refs.contextMenu.style.display = 'block';
    this.$refs.contextMenu.style.top = `${event.clientY + 2}px`;
    this.$refs.contextMenu.style.left = `${event.clientX}px`;
    document.addEventListener('click', this.clickOutside);
  }

  closeMenu() {
    this.$refs.contextMenu.style.display = '';
    document.removeEventListener('click', this.clickOutside);
  }

  clickOutside(event: Event) {
    if (event.target && !this.$refs.contextMenu.contains(event.target as Node)) {
      this.closeMenu();
    }
  }

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  optionClicked(option: ContextMenuOption, $event: Event) {
    return this.data;
  }

  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside);
  }
}
