
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import FormatLabelMixin from '@/mixins/FormatLabelMixin';
import FieldToStringGuesser from '@/components/List/FieldToStringGuesser.vue';
import FieldToImageGuesser from '@/components/View/ImageField.vue';
import { ApiResource } from '@/types/resources/ApiResource';

@Component({
  components: { FieldToImageGuesser, FieldToStringGuesser },
})
export default class ViewFields extends mixins(FormatLabelMixin) {
  @Prop({ type: Object, required: true }) resource!: ApiResource;

  get resourceWithoutTranslations() {
    const r: ApiResource = {
      '@id': '',
      '@context': '',
      '@type': '',
    };
    Object.keys(this.resource).forEach((key: string) => {
      if (!key.startsWith('translation')) {
        r[key] = this.resource[key];
      }
    });

    return r;
  }
}
