
import { Component, Prop, Vue } from 'vue-property-decorator';
import { removeApiPrefix } from '@/utils/apiUtils';
import { asyncForEach } from '@/utils/asyncForEach';

interface FieldContent { text: string; link?: string }

/**
 * TODO: Refactor all those function into separate components, eg. DateFieldGuesser,
 * StringFieldGuesser, etc. This component should then just decide what sub-component
 * to render.
 * TODO: Handle Boolean
 */
@Component
export default class FieldToStringGuesser extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ type: [String, Array, Object, Number, Date, Boolean], required: true }) field!: any;

  /**
   * Text to show for the field
   */
  private fieldContent: FieldContent|FieldContent[] = { text: '' };

  /**
   * Format a string or a Date to dd.mm.yyyy hh:mm
   * @param value
   */
  formatDate(value: Date | string): string {
    let date: Date;
    if (typeof value === 'string') {
      // Test if date string contains timezone info at the end.
      // If yes, create Date object without it.
      const match = value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\+\d{2}:\d{2})?$/);
      if (match?.length === 2 && match[1]) {
        date = new Date(value.replace(match[1], ''));
      } else {
        date = new Date(value);
      }
    } else {
      date = value;
    }

    let day: string | number = date.getDate();
    if (day < 10) {
      day = `0${day}`;
    }

    let month: string | number = date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }

    const year = date.getFullYear();
    let hours: string | number = date.getHours();

    if (hours < 10) {
      hours = `0${hours}`;
    }

    let minutes: string | number = date.getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  /**
   * This function get a fitting value for an object. It first tests
   * if a title or name attribute is available. If not, it make an
   * API Request (if defined) and then returns a value.
   * Date is also included in here, in case a Date object finds its
   * way into this component.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getFieldTextForObject(
    object: { '@id'?: string; title?: string; name?: string },
  ): Promise<FieldContent|FieldContent[]> {
    if (object['@id']) {
      // If a title or name exists, no request is needed
      if (object.title) {
        return { text: object.title, link: removeApiPrefix(object['@id']) };
      }

      if (object.name) {
        return { text: object.name, link: removeApiPrefix(object['@id']) };
      }

      // Else we request the resource
      if (object['@id'].startsWith(process.env.VUE_APP_API_PREFIX)) {
        const response = await this.$api.get(object['@id']);
        if (response.status === 200 && response.data) {
          // For persons we need email
          switch (response.data['@type']) {
            case 'https://schema.org/Person':
              return { text: response.data.email, link: removeApiPrefix(response.data['@id']) };
            default:
              return { text: response.data['@id'], link: removeApiPrefix(response.data['@id']) };
          }
        }

        return { text: this.$t('error.loadingProperty') as string };
      }
    }

    if (this.field instanceof Date) {
      return { text: this.formatDate(this.field) };
    }

    // If nothing else fits, just return array with properties of field
    return Object.keys(this.field).map((key) => ({ text: `${key}: ${this.field[key]}` }));
  }

  /**
   * This function gets the text for the given field.
   * For consistency and for recursive usage, it always returns an array.
   * This array only then has more than one element, if the input field
   * had been an array itself.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getFieldText(field: any): Promise<FieldContent|FieldContent[]> {
    // Join Array values
    if (Array.isArray(field)) {
      const values: FieldContent[] = [];
      // Async/await/recursive call function on elements in field
      // and push results into values
      await asyncForEach(field, async (f) => {
        const value = await this.getFieldText(f);
        values.push(value as FieldContent);
      });
      return values;
    }

    // Test if string contains ISO8601-formatted date
    if (typeof field === 'string'
      && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\+\d{2}:\d{2})?$/.test(this.field)) {
      return { text: this.formatDate(this.field) };
    }

    if (typeof field === 'object' && !Array.isArray(field)) {
      return this.getFieldTextForObject(field);
    }

    if (typeof field === 'string') {
      if (field.startsWith('/api')) {
        return this.getFieldTextForObject({ '@id': field });
      }
    }

    return { text: field };
  }

  /**
   * When mounted, parse the field and set fieldContent accordingly
   */
  async mounted() {
    this.fieldContent = await this.getFieldText(this.field);
  }
}
